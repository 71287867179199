import React from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
} from "react-router-dom";
import axios from "axios";

import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Nav from "./pages/components/common/Nav";
import { changeLanguage } from "./i18n";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const Activation = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token_local = urlParams.get("confirmationHash");
  const email_local = urlParams.get("email");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/api/users/account/confirmMail`, {
        email: email_local,
        token: token_local,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          //alert("驗證完成，將重新自動登入！");
          location.reload();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sendConfirmMail = () => {
    if (userIsValid === "false") return;
    axios
      .post("/api/account_confirm", {
        email: userEmail,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          alert("已成功發送，請至信箱確認");
        } else {
          //alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const autoSubmit = () => {
    axios
      .post(`/api/users/account/confirmMail`, {
        email: email_local,
        token: token_local,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setTimeout(function () {
    if (token_local != null && email_local != null) {
      document.getElementById("token").value = token_local;
      autoSubmit();
    }
  }, 1000);

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <Nav />
      <div
        className="w3-container w3-light-grey"
        style={{
          padding: "100px 16px",
          margin: "0 -24px",
          fontWeight: "600",
          height: "100%",
        }}
        id="contact"
      >
        <p className="w3-center w3-large"></p>
        <div style={{ marginTop: "24px" }}>
          <div className="w3-col l12">
            <div className="w3-col l4">&nbsp;</div>
            <div className="w3-col l4">
              <h3 className="w3-center" style={{ fontWeight: "700" }}>
                帳號驗證中
              </h3>
              <p style={{ margin: "20px 3vw" }}>
                <p style={{ margin: "20px 3vw" }}>
                  <input
                    className="w3-input w3-border m-radius"
                    type="text"
                    placeholder="輸入驗證碼"
                    required
                    name="token"
                    id="token"
                  />

                  <br />
                  <button
                    className="w3-button w3-black"
                    type="submit"
                    style={{ width: "100%" }}
                    onClick={handleSubmit}
                  >
                    送出
                  </button>
                </p>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Join = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token_local = urlParams.get("confirmationHash");
  const email_local = urlParams.get("email");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/api/users/account/joinMail`, {
        email: email_local,
        token: token_local,
      })
      .then((res) => {
        if (res.data["successful"] !== false) {
          alert("加入完成，將重新自動登入！");
          window.location.href = "/";
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form>
        <div style={{ display: "flex" }}>
          <input
            className="form-control"
            id="token"
            style={{ marginRight: "15px", display: "none" }}
          />
          <input
            type="submit"
            className="btn btn_Orange"
            style={{ width: "150px", textAlign: "center" }}
            value="加入Playreal的新組織"
            onClick={handleSubmit}
          />
        </div>
      </form>
      <div
        style={{
          fontSize: "1em",
          fontWeight: "500",
          marginTop: "5vh",
          color: "#a5a5a5",
        }}
      >
        如有任何問題，請
        <span
          style={{
            fontSize: "1em",
            fontWeight: "700",
            marginTop: "5vh",
            color: "#616161",
          }}
        >
          與我們聯絡。
        </span>
      </div>
    </div>
  );
};

const ProtectedJoinRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hasInviteParams =
          urlParams.get("confirmationHash") && urlParams.get("email");

        if (hasInviteParams) {
          return <Join {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

const App = () => {
  const { language } = document.getElementById("root").dataset;

  React.useEffect(() => {
    changeLanguage(language);
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/signup">
          <RegistrationPage />
        </Route>
        <Route path="/forgot">
          <ForgetPasswordPage />
        </Route>
        <Route path="/reset_password">
          <ResetPasswordPage />
        </Route>
        <Route path="/confirmation">
          <Activation />
        </Route>
        <ProtectedJoinRoute path="/joinGroup" component={Join} />
        <Route path="/">
          <Redirect
            to={{ pathname: "/login", search: window.location.search }}
          />
        </Route>
      </Switch>
    </Router>
  );
};

root.render(<App />);
